import React, { useState } from 'react';

import { Row, Col, Upload, Button, Divider, Typography, Tabs, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { ResponsiveContainer, ComposedChart, Bar, Legend, Line, CartesianGrid, XAxis, YAxis, Tooltip, LabelList } from 'recharts';

import * as parse from 'csv-parse';
import * as R from 'ramda';
import * as moment from 'moment';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

const Home = () => {
  const [count, setCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [sales, setSales] = useState([]);

  const [busy, setBusy] = useState(false);

  const props = {
    name: 'file',
    accept: '.csv,text/csv',
    multiple: true,
    maxCount: 16,
    showUploadList: false,
    customRequest: (it) => {
      it.onSuccess({}, it.file)
    },
    beforeUpload: file => {
      let valid = file.type === 'text/csv' || file.name.endsWith('.csv')
      if (!valid) {
        message.error(`${file.name} is not a CSV file`);
      }
      return valid ? true : Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
      setCount(info.fileList.length)
      setFiles(info.fileList)
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const f = (it) => it.toFixed(2)

  const chart1 = () => {
    let groups = R.groupBy((it) => it['Category'], sales);
    let data = R.mapObjIndexed((g, key, obj) => {
      let mSales = R.map((it) => R.defaultTo(0)(parseInt(it['Mo. Sales'])), g);
      let avg = R.mean(mSales);
      let median = R.median(mSales)
      let revenue = R.mean(R.map((it) => R.defaultTo(0)(parseFloat(R.replace(',', '' ,R.replace('$', '', it['Mo. Revenue'])))), g));
      let listing = R.defaultTo(0)(R.find((it) => it > 0, R.map((it) => parseInt(it['Listing']), g)));

      let launch = R.groupBy((it) => {
        let diff = moment().diff(R.defaultTo(2077)(moment(it['Date First Available'], "MM/DD/YYYY")), 'years', true);
        return diff < 0.5 ? '<0.5 Year' :
          diff < 1.0 ? '0.5-1 Year' :
            diff < 1.5 ? '1-1.5 Year' :
              diff < 2.0 ? '1.5-2 Year' :
                diff < 2.5 ? '2-2.5 Year' :
                  diff < 3.0 ? '2.5-3 Year' : '>3 Year'
      }, g);
      let brand =
        R.groupBy((it) => {
          let len = it.length;
          return len === 1 ? 'Brand Count: 1' :
            len === 2 ? 'Brand Count: 2' :
              len === 3 ? 'Brand Count: 3' :
                len > 3 ? 'Brand Count: >3' : 'WTF'
        }, R.values(R.groupBy((it) => it['Brand'], g)));

      let ratings = R.map((it) => R.defaultTo(0)(parseInt(it['Rating Number'])), g);
      let ratingAvg = R.mean(ratings);
      let ratingMedian = R.median(ratings)
      let ratingMin = R.reduce(R.min, ratings[0], ratings)

      return {
        'Category': key,
        'Mo. Sales (Sum)': R.sum(mSales),
        'Listing': listing,
        'Mo. Sales / Listing': R.sum(mSales) / listing,
        'Mo. Sales (Avg)': avg,
        'Mo. Sales (Me)': median,
        'Mo. Sales (Avg / Me)': avg / median,
        'Mo. Revenue (Avg)': revenue,
        'Rating Number (Avg)': ratingAvg,
        'Rating Number (Me)': ratingMedian,
        'Rating Number (Min)': ratingMin,
        'Rating Number (Avg / Me)': ratingAvg / ratingMedian,
        ...R.mapObjIndexed((v, k, o) => v.length, launch),
        ...R.mapObjIndexed((v, k, o) => v.length, brand),
      }
    }, groups);
    console.log(data)
    // return R.slice(0, 16, R.values(data));
    return R.values(data);
  }

  const launchYear = () => {
    let y = R.map((it) => {
      return {
        data: it,
        year: R.defaultTo(2077)(parseInt(moment(it['Date First Available'], "MM/DD/YYYY").year()))
      }
    }, sales);
    let g = R.groupBy((it) => it.year, y);

    let data = R.values(R.mapObjIndexed((v, k, o) => {
      return {
        Count: v.length, Year: k,
        Ratio: R.filter((it) => it.year <= v[0].year, y).length / y.length
      }
    }, g))
    console.log(data)
    return data;
  }

  const price = () => {
    let prices = R.map((it) => {
      return {
        price: R.defaultTo(0)(parseFloat(R.replace('$', '', it['Price']))),
        data: it
      }
    }, sales);

    if (prices.length === 0) return [];

    let sorted = R.sort((a, b) => a.price - b.price, prices)

    let max = Math.round(sorted[sorted.length - 1].price)
    let min = Math.floor(sorted[0].price)

    let step = Math.round((max - min) / 15)

    let ranges = R.map((it) => min + (step * (it + 1)), R.range(0, 15))

    let data = R.groupBy((it) => R.find((r) => it.price < r)(ranges), prices)

    console.log(max, min, ranges, data)
    let keepSorted = R.sortBy(R.prop('Value'))

    return keepSorted(R.values(R.mapObjIndexed((v, k, o) => {
      return {
        Value: parseFloat(k),
        'Price Range': `$${k - step} - $${k}`,
        Count: v.length,
        Ratio: R.filter((it) => it.price < v[0].price, sorted).length / prices.length,
        'Mo. Sales (Avg)': R.mean(R.map((it) => R.defaultTo(0)(parseInt(it.data['Mo. Sales'])), v)),
        'Rank (Avg)': R.mean(R.map((it) => R.defaultTo(0)(parseInt(it.data['Rank'])), v))
      }
    }, data)));
  }

  const renderCharts = () => {
    let progress = 0;
    let data = [];

    setSales(data);

    files.forEach((it) => {
      let reader = new FileReader();

      reader.onload = function (e) {
        let content = e.target.result;

        parse(content, {
          columns: true,
        }, (err, output) => {
          if (!err) {

            data = data.concat(output);

            progress += 1;
            if (progress === count) {
              setSales(data);
              setBusy(false);

              // console.log(sales);
            }

          } else message.error(`Cannot parse file.`);
        });
      }

      reader.onerror = function (e) {
        message.error(`Cannot read file.`);
      }

      setBusy(true);
      reader.readAsText(it.originFileObj, "UTF-8");
    });

  }

  return (
    <div style={{ padding: '24px 24px' }}>
      <Row gutter={[16, 16]} align="middle">
        <Col><Button type="primary" block onClick={renderCharts} disabled={count === 0 || busy}>开始分析</Button></Col>
        <Col>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>上传 CSV 文件</Button>
          </Upload>
        </Col>
        <Col><Text>已上传 {count} 个文件</Text></Col>
        <Col span={2}></Col>
      </Row>
      <Divider style={{ marginBottom: '0' }} />

      <Tabs defaultActiveKey="1" tabPosition="top" hidden={sales.length === 0}>
        <TabPane tab="M. Sales (Avg / Me)" key="1">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>M. Sales (Avg / Me)</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Mo. Sales (Avg)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16, formatter: f }} />
                <Bar dataKey="Mo. Sales (Me)" barSize={42} fill="#ffa545" label={{ position: 'top', offset: 16, formatter: f }} />
                <Line type="linear" dataKey="Mo. Sales (Avg / Me)" stroke="#757575" strokeWidth={2} yAxisId="right" label={{ position: 'right', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Mo. Sales", angle: -90, position: 'insideLeft' }} padding={{ top: 100 }} />
                <YAxis label={{ value: "Mo. Sales (Avg / Me)", angle: 90, position: 'insideRight' }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Category" />

                {/* <Brush dataKey="Category" height={30} stroke="#8884d8" /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="M. Sales - M. Revenue" key="2">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>M. Sales - M. Revenue</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Mo. Sales (Avg)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16, formatter: f }} />
                <Bar dataKey="Mo. Revenue (Avg)" barSize={42} fill="#ffa545" yAxisId="right" label={{ position: 'top', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Mo. Sales (Avg)", angle: -90, position: 'insideLeft' }} padding={{ top: 100 }} />
                <YAxis label={{ value: "Mo. Revenue (Avg)", angle: 90, position: 'insideRight' }} padding={{ top: 100 }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Category" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="M. Sales / L" key="3">
          <Row style={{ padding: '12px' }} justify="center">
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>M. Sales / L</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Mo. Sales (Sum)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16 }} />
                <Bar dataKey="Listing" barSize={42} fill="#ffa545" label={{ position: 'top', offset: 16 }} />
                <Line type="monotone" dataKey="Mo. Sales / Listing" stroke="#757575" strokeWidth={2} yAxisId="right" label={{ position: 'right', offset: 16, formatter: f }} />

                <YAxis padding={{ top: 100 }} />
                <YAxis label={{ value: "Mo. Sales / Listing", angle: 90, position: 'insideRight' }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Category" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Launch Time" key="4">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Launch Time</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="<0.5 Year" stackId="l" barSize={42} fill="#8fbde3"  >
                  <LabelList dataKey="<0.5 Year" fill="white" />
                </Bar>
                <Bar dataKey="0.5-1 Year" stackId="l" barSize={42} fill="#5f99c9"  >
                  <LabelList dataKey="0.5-1 Year" fill="white" />
                </Bar>
                <Bar dataKey="1-1.5 Year" stackId="l" barSize={42} fill="#4084bd" >
                  <LabelList dataKey="1-1.5 Year" fill="white" />
                </Bar>
                <Bar dataKey="1.5-2 Year" stackId="l" barSize={42} fill="#2a6ca3"  >
                  <LabelList dataKey="1.5-2 Year" fill="white" />
                </Bar>
                <Bar dataKey="2-2.5 Year" stackId="l" barSize={42} fill="#16568c"  >
                  <LabelList dataKey="2-2.5 Year" fill="white" />
                </Bar>
                <Bar dataKey="2.5-3 Year" stackId="l" barSize={42} fill="#0a3c66"  >
                  <LabelList dataKey="2.5-3 Year" fill="white" />
                </Bar>
                <Bar dataKey=">3 Year" stackId="l" barSize={42} fill="#04223b">
                  <LabelList dataKey=">3 Year" fill="white" />
                </Bar>

                {/* <Line type="linear" dataKey=">3 Year" />
                <Line type="linear" dataKey="2.5-3 Year" />
                <Line type="linear" dataKey="2-2.5 Year" />
                <Line type="linear" dataKey="1.5-2 Year" />
                <Line type="linear" dataKey="1-1.5 Year" />
                <Line type="linear" dataKey="0.5-1 Year" />
                <Line type="linear" dataKey="<0.5 Year" /> */}

                <YAxis label={{ value: "Count", angle: -90, position: 'insideLeft' }} />
                <XAxis dataKey="Category" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Brand Distribution" key="5">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Brand Distribution</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Brand Count: 1" stackId="b" barSize={42} fill="#0670c7" >
                  <LabelList dataKey="Brand Count: 1" />
                </Bar>
                <Bar dataKey="Brand Count: 2" stackId="b" barSize={42} fill="#f2a31b" >
                  <LabelList dataKey="Brand Count: 2" />
                </Bar>
                <Bar dataKey="Brand Count: 3" stackId="b" barSize={42} fill="#9c9c9c" >
                  <LabelList dataKey="Brand Count: 3" />
                </Bar>
                <Bar dataKey="Brand Count: >3" stackId="b" barSize={42} fill="#e3e056"  >
                  <LabelList dataKey="Brand Count: >3" />
                </Bar>

                <YAxis label={{ value: "Count", angle: -90, position: 'insideLeft' }} />
                <XAxis dataKey="Category" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Launch Year" key="6">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Launch Year</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={launchYear()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Count" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16 }} />
                <Line type="linear" dataKey="Ratio" stroke="#757575" strokeWidth={2} yAxisId="right" label={{ position: 'top', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Count", angle: -90, position: 'insideLeft' }} />
                <YAxis label={{ value: "Ratio", angle: 90, position: 'insideRight' }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Year" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Price" key="7">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Price</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={price()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Count" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16 }} />
                <Line type="linear" dataKey="Ratio" stroke="#757575" strokeWidth={2} yAxisId="right" label={{ position: 'right', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Count", angle: -90, position: 'insideLeft' }} />
                <YAxis label={{ value: "Ratio", angle: 90, position: 'insideRight' }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Price Range" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Price - M. Sales" key="8">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Price - M. Sales</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={price()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Mo. Sales (Avg)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Mo. Sales (Avg)", angle: -90, position: 'insideLeft' }} padding={{ top: 100 }} />
                <XAxis dataKey="Price Range" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Price - Rank" key="9">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Price - Rank</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={price()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Rank (Avg)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16, formatter: (it) => it.toFixed(0) }} />

                <YAxis label={{ value: "Rank (Avg)", angle: -90, position: 'insideLeft' }} padding={{ top: 100 }} />
                <XAxis dataKey="Price Range" />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
        <TabPane tab="Ratings (Avg / Me)" key="10">
          <Row style={{ padding: '12px' }}>
            <Title style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Rating Number (Avg / Me)</Title>
            <ResponsiveContainer height="80%" minHeight={600}>
              <ComposedChart data={chart1()}>
                <CartesianGrid strokeDasharray="3 3" />
                <Legend />
                <Tooltip />

                <Bar dataKey="Rating Number (Avg)" barSize={42} fill="#0670c7" label={{ position: 'top', offset: 16, formatter: f }} />
                <Bar dataKey="Rating Number (Me)" barSize={42} fill="#ffa545" label={{ position: 'top', offset: 16, formatter: f }} />
                <Bar dataKey="Rating Number (Min)" barSize={42} fill="#215732" label={{ position: 'top', offset: 16 }} />
                <Line type="linear" dataKey="Rating Number (Avg / Me)" stroke="#757575" strokeWidth={2} yAxisId="right" label={{ position: 'right', offset: 16, formatter: f }} />

                <YAxis label={{ value: "Rating Number", angle: -90, position: 'insideLeft' }} padding={{ top: 100 }} />
                <YAxis label={{ value: "Rating Number (Avg / Me)", angle: 90, position: 'insideRight' }} yAxisId="right" orientation="right" />
                <XAxis dataKey="Category" />

                {/* <Brush dataKey="Category" height={30} stroke="#8884d8" /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Home;
