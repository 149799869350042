import './body.css';

import 'antd/dist/antd.css';

import React from 'react';
import '@ant-design/compatible';

import { Layout } from 'antd';

import Header from './components/Header';

const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: '100%',
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
    <AppLayout>{children}</AppLayout>
);

export default App;
